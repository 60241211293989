.custom-research-modal {
  --bs-modal-width: 1400px;
  .table-bordered {
    thead,
    tbody,
    tr,
    td,
    th {
      border-color: #8f9199;
    }
  }
  .modal-header {
    .modal-close-area {
      width: 7rem;
      button {
        float: right;
        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
  .modal-content {
    height: 90vh;
    table {
      td {
        padding: 0.5rem;
        vertical-align: middle;
      }
      .tb-bg-custom {
        font-weight: bold !important;
        background-color: #ccccccd9 !important;
        color: black !important;
        text-align: center;
      }

      .tb-bg-custom-1 {
        font-weight: bold !important;
        background-color: #efefef !important;
        color: black !important;
        text-align: center;
      }
      .td-custom-blue {
        background-color: rgb(223, 230, 247) !important;
        font-weight: bold;
      }
      .tb-bg-custom-2 {
        font-weight: bold !important;
        background-color: #caa0e5 !important;
        color: black !important;
        text-align: center;
      }
      .tb-bg-custom-3 {
        font-weight: bold !important;
        background-color: rgb(182, 201, 236) !important;
        color: black !important;
        text-align: center;
      }
      .checkbox label {
        opacity: 1 !important;
      }

      input[type="radio"] {
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid #8f9199;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        transform: scale(1.5);
        &:checked {
          background-color: #7366ff;
          border: 3px solid #fff;
          box-shadow: 0 0 0 1px #7366ff;
        }
      }
      input[type="checkbox"] {
        appearance: none;
        width: 15px;
        height: 15px;
        transform: scale(1.5);
        border: 1px solid #8f9199;
        border-radius: 3px;
        cursor: pointer;

        &:checked {
          background-color: #7366ff;
          border-color: #7366ff;
          &::after {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            margin: 2px auto;
          }
        }
      }
    }
  }
  * {
    word-break: keep-all;
  }
  .modal-footer-with-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .footer-left {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .footer-right {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.custom-research-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;

  .modal-dialog {
    width: 100%;
    margin: 1.75rem auto;
    position: relative;
  }

  .modal-content {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    position: relative;
    margin: 0.5rem;
    padding: 1rem;
    max-height: 90vh;
    z-index: 9999;
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .modal-footer > * {
    margin: 0.25rem;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
