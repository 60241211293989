
.vuecal {
    .vuecal__arrow {
        color: white;
    }
    .vuecal__title-bar,
    .vuecal__cell-events-count {
        background-color: var(--theme-deafult);
        color: white;
    }

    .vuecal__cell--today,
    .vuecal__cell--current {
        background-color: #f0f0ff66 !important;
    }

    .vuecal__cell--selected {
        background-color: transparent;
    }

    .vuecal__cell--selected:before {
        border-color: var(--theme-deafult);
    }
    
    .vuecal__event {
        word-break: keep-all;
        cursor: pointer;
        // &.major1{
        //     background-color: #a4e6d2e6;
        //     border: 1px solid rgb(125, 184, 166);
        //     color: grey;
        // }
        // &.major2{
        //     background-color: #44bbfc9a;
        //     border: 1px solid rgb(80, 180, 235);
        // }
        // &.major3{
        //     background-color: #ffff0026;
        //     border: 1px solid rgba(255, 210, 0, 1);
        //     color: grey;
        // }
        // &.major4{
        //     background-color: #ff99d3d9;
        //     border: 1px solid #ff74c3d9;
        // }
        background-color:var(--theme-deafult);
        border: 1px solid #5248c5;
        color: #fff;
    }

    .month-view .vuecal__cell-content {
        justify-content: start;

        .month-cell-title {
            padding: 0.5rem;
            display: flex;
            justify-content: end;
            align-items: center;

            .month-cell-day {
                /* font-weight: 900; */
                font-size: 1rem;
            }
        }
        .month-count{
            display: inline-block;
            padding: 0.2rem 0.7rem;
            margin: 0 2px;
            color: #fff;
            border-radius: 12px;
            font-size: 0.8rem;
            cursor: pointer;
            
            &.month-total{
                background-color:  var(--complete-color);
            }
            
            &.month-not-progress{
                background-color:  var(--not-progress-color);
            }
        }
        .vuecal__event_more {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 767px) {
    .month-count{
            padding: 0.2rem 0.3rem !important;
        span{
            display: none;
        }
    }
}