.vue-treeselect{
    .vue-treeselect__multi-value-item{
      background: var(--theme-deafult);
      color:white;
    }
    .vue-treeselect__icon.vue-treeselect__value-remove{
      color:white;
    }
    .vue-treeselect__menu{
       .check{
        background: var(--theme-deafult);
       }
    }
  }