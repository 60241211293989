.custom-data-table {
  * {
    word-break: keep-all;
  }
  border: 0 !important;
  cursor: pointer;
  table {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  thead th {
    border-bottom: 1px solid #7366ff !important;
  }

  th,
  td {
    padding: 0.6rem !important;
    td {
      padding: 0 !important;
    }
  }
  &.custom-modal {
    .vue3-easy-data-table__main {
      height: 27rem;
    }
  }
  .vue3-easy-data-table__footer {
    justify-content: center;
    border: 0;
    margin-top: 1rem;
    .select-items {
      z-index: 1 !important;
      .selected {
        background-color: var(--theme-deafult) !important;
      }
    }
    .button {
      padding: 0.375rem 0.75rem;
      color: var(--theme-deafult);
      border-radius: 0 !important;

      font-size: 1rem;
      line-height: inherit;
      border-color: #e9ecef;
      &:first-of-type {
        border-left: 0 !important;
      }
      &:last-of-type {
        border-right: 0 !important;
      }
      &.active {
        padding: calc(0.375rem + 1px) 0.75rem;
        color: #fff !important;
        background-color: var(--theme-deafult) !important;
        border: 0 !important;
      }
    }

    .previous-page__click-button {
      color: var(--theme-deafult);
      padding: 0.375rem 0.75rem;
      margin: 0 !important;
      font-size: 1rem;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #e9ecef !important;

      &.first-page {
        background-color: #e9ecef !important;
      }

      span {
        border-color: var(--theme-deafult) !important;
      }
    }

    .next-page__click-button {
      color: var(--theme-deafult);
      padding: 0.375rem 0.75rem;
      margin: 0 !important;
      font-size: 1rem;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #e9ecef !important;

      &.last-page {
        background-color: #e9ecef !important;
      }

      span {
        border-color: var(--theme-deafult) !important;
      }
    }
  }
}
