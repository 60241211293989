:root {
  --complete-color: rgb(115, 102, 255);
  --progress-color: rgb(107, 153, 219);
  --expected-color: rgb(98, 203, 182);
  --not-progress-color: rgba(252, 69, 56);
}

.s-complete-light {
  background-color: rgba(115, 102, 255, 0.5) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-progress-light {
  background-color: rgba(107, 153, 219, 0.5) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-expected-light {
  background-color: rgba(98, 203, 182, 0.5) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-not-progress-light {
  background-color: rgb(255, 234, 234) !important;
  color: red !important;
  border: 0 !important;
}

.s-complete-title.active {
  background-color: rgba(115, 102, 255, 0.1) !important;
}
.s-progress-title.active {
  background-color: rgba(107, 153, 219, 0.2) !important;
}
.s-expected-title.active {
  background-color: rgba(98, 203, 182, 0.2) !important;
}
.s-not-progress-title.active {
  background-color: rgb(255, 246, 246) !important;
}

.s-complete {
  background-color: var(--complete-color) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-progress {
  background-color: var(--progress-color) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-expected {
  background-color: var(--expected-color) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-not-progress {
  background-color: var(--not-progress-color) !important;
  color: #ffff !important;
  border: 0 !important;
}
.s-btn {
  padding: 0.375rem 1.75rem;
  width: 8rem;
}
.vuecal__event {
  &.vue-progress {
    background-color: rgba(107, 153, 219, 0.6) !important;
    color: #ffff !important;
    border: 1px solid var(--progress-color) !important;
  }
  &.vue-complete {
    background-color: rgba(115, 102, 255, 0.6) !important;
    color: #ffff !important;
    border: 1px solid var(--complete-color) !important;
  }
  &.vue-expected {
    background-color: rgba(98, 203, 182, 0.6) !important;
    color: #ffff !important;
    border: 1px solid var(--expected-color) !important;
  }
  &.vue-notProgress {
    background-color: rgba(252, 69, 56, 0.6) !important;
    color: #ffff !important;
    border: 1px solid var(--not-progress-color) !important;
  }
}
.week-content {
  .modal-content {
    &.week-vue-progress {
      border-left: 6px solid var(--progress-color);
    }
    &.week-vue-complete {
      border-left: 6px solid var(--complete-color);
    }
    &.week-vue-expected {
      border-left: 6px solid var(--expected-color);
    }
    &.week-vue-notProgress {
      border-left: 6px solid var(--not-progress-color);
    }
  }
}
.month-content {
  .event-circle.circle-vue-progress {
    color: var(--progress-color);
  }
  .event-circle.circle-vue-complete {
    color: var(--complete-color);
  }
  .event-circle.circle-vue-expected {
    color: var(--expected-color);
  }
  .event-circle.circle-vue-notProgress {
    color: var(--not-progress-color);
  }
}
.modal_schedule_detail {
  font-size: 1rem;
  color: #fff;
  &.s-detail-vue-progress {
    background-color: var(--progress-color);
  }
  &.s-detail-vue-complete {
    background-color: var(--complete-color);
  }
  &.s-detail-vue-expected {
    background-color: var(--expected-color);
  }
  &.s-detail-vue-notProgress {
    background-color: var(--not-progress-color);
  }
}

.not-progress-modal .vue3-easy-data-table__main.fixed-header {
  max-height: 55vh !important;
}
